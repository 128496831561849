export default function numberToRomanNumber(num: number): string {
  const romanNumerals = {
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let result = '';

  for (const numeral of Object.keys(romanNumerals)) {
    const quotient = Math.floor(num / romanNumerals[numeral]);
    num -= quotient * romanNumerals[numeral];
    result += numeral.repeat(quotient);
  }

  return result;
}
