import scaleImage from '@/utilities/scaleImage';
import classNames from 'classnames';

export default function Image({
  src,
  alt,
  caption = undefined,
  fullwidth = false,
  boxed = false,
  width = 0,
  height = 0,
}) {
  return (
    <figure
      className={classNames('Image', {
        'Image--original': !fullwidth,
        'Image--fullwidth': fullwidth,
        'Image--boxed': boxed,
      })}
    >
      <div className="Image__container">
        <picture>
          <source
            media="(min-width: 767px)"
            srcSet={`${scaleImage({
              url: src,
              width,
              height,
            })}`}
          />
          <source
            media="(min-width: 481px)"
            srcSet={`${scaleImage({
              url: src,
              width: 767,
              height: 415,
            })}`}
          />
          <source
            media="(max-width: 480px)"
            srcSet={`${scaleImage({
              url: src,
              width: 480,
              height: 260,
            })}`}
          />
          <img
            className="Image__img"
            src={scaleImage({ url: src, width, height })}
            alt={alt}
            loading="lazy"
          />
        </picture>
      </div>
      {caption && <figcaption className="Image__caption">{caption}</figcaption>}
    </figure>
  );
}
