/**
 * https://www.contentful.com/developers/docs/references/images-api/
 */
export default function scaleImage({
  url = '',
  width = 0,
  height = 0,
  quality = 75,
  format = 'webp',
  fit = 'fill',
  focus = 'faces',
}) {
  return typeof url === 'string'
    ? `/assets/ctf/images/${url.replace(
        'https://images.ctfassets.net/',
        ''
      )}?fm=${format}&q=${quality}&fit=${fit}&f=${focus}&w=${width || ''}&h=${
        height || ''
      }`
    : url;
}
