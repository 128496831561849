import LinkWrapper from '@/components/LinkWrapper';
import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import Image from 'next/image';

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer__wrapper">
        {/* Footer Section Top */}
        <section className="Footer__section Footer__section--top">
          <div className="Footer__topColumn">
            <div className="Footer__titleWrapper">
              <div className="Footer__title">Responsible Gaming</div>
            </div>
            <div className="Footer__box">
              <div className="Footer__text--small">
                Erlaubt gemäß{' '}
                <a
                  href="https://www.gluecksspiel-behoerde.de/de/fuer-spielende/uebersicht-erlaubter-anbieter-whitelist"
                  className="Footer__link"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Externer Link: https://www.gluecksspiel-behoerde.de (öffnet neues Fenster)"
                >
                  White-List.
                </a>{' '}
              </div>
              <div className="Footer__infoContainer Footer__infoContainer--logos">
                <div>
                  <a
                    href="https://www.european-lotteries.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Externer Link: https://www.european-lotteries.org (öffnet neues Fenster)"
                  >
                    <Image
                      alt="The European Lotteries Logo"
                      src="/assets/images/logo-the-european-lotteries--white.svg"
                      className="Footer__image"
                      loading="lazy"
                      width={226}
                      height={60}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.world-lotteries.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Externer Link: https://www.world-lotteries.org (öffnet neues Fenster)"
                  >
                    <Image
                      alt="World Lottery Association Logo"
                      src="/assets/images/WLA-Logo-2022--white.svg"
                      className="Footer__image"
                      loading="lazy"
                      width={285}
                      height={50}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="Footer__topColumn">
            <div className="Footer__titleWrapper">
              <div className="Footer__title">Jugendschutz</div>
            </div>
            <div className="Footer__box">
              <div className="Footer__infoContainer Footer__infoContainer--logoAndText">
                <div className="Footer__center">
                  <Image
                    alt="Spielen ab 18 Jahren!"
                    src="/assets/images/logo-ab18-white.svg"
                    className="Footer__image"
                    loading="lazy"
                    width={40}
                    height={48}
                  />
                </div>
                <span className="Footer__text Footer__text--bold">
                  Spielteilnahme ab 18 Jahren!
                </span>
              </div>
            </div>
          </div>
          <div className="Footer__topColumn">
            <div className="Footer__titleWrapper">
              <div className="Footer__title">Spielerschutz</div>
            </div>
            <div className="Footer__box">
              <div>
                <span className="Footer__text--small">
                  Glücksspiel kann süchtig machen. Eine bundesweite Übersicht zu
                  Hilfsangeboten gegen Glücksspielsucht finden Sie unter{' '}
                  <a
                    className="Footer__link"
                    href="https://www.check-dein-spiel.de"
                    target="_blank"
                    title="Externer Link: https://www.check-dein-spiel.de (öffnet neues Fenster)"
                    rel="noopener noreferrer"
                  >
                    www.check-dein-spiel.de
                  </a>{' '}
                  oder{' '}
                  <a
                    className="Footer__link"
                    href="https://www.bundesweit-gegen-gluecksspielsucht.de/"
                    target="_blank"
                    title="Externer Link: https://www.bundesweit-gegen-gluecksspielsucht.de (öffnet neues Fenster)"
                    rel="noopener noreferrer"
                  >
                    www.buwei.de
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>
          <div className="Footer__topColumn">
            <div className="Footer__titleWrapper">
              <div className="Footer__title">Aktuelle Auszeichnung</div>
            </div>
            <div className="Footer__box">
              <div className="Footer__infoContainer Footer__infoContainer--logoAndText Footer__infoContainer__box">
                <LinkWrapper
                  href="/ueber/tests-und-auszeichnungen/dtgv-deutscher-kunden-award-2023-24"
                  title='Deutscher Kunden Award 2023/24 für LOTTO.de: 1. Platz Kundenzufriedenheit in der Branche "Online-Lotto-Anbieter"'
                  className="Footer__center"
                  tabIndex={0}
                  rel="noopener noreferrer"
                >
                  <div className="Footer__box-conent">
                    <Image
                      alt="Signet für den Deutschen Kunden-Award 2023/24"
                      src="/assets/images/dka_kundenzufriedenheit-24-new.webp"
                      className="Footer__image"
                      loading="lazy"
                      width={75 * 0.6}
                      height={126 * 0.6}
                    />
                    <div className="Footer_text_wrapper">
                      <span className="Footer__text Footer__link ">
                        Deutscher Kunden-Award 2023/24
                      </span>
                    </div>
                  </div>
                </LinkWrapper>
              </div>
            </div>
          </div>
        </section>
        {/* Footer Section Bottom */}
        <section className="Footer__section Footer__section--bottom">
          <div className="FooterNavBar">
            {[
              {
                path: '/impressum',
                title: 'Impressum',
              },
              {
                path: '/datenschutzerklaerung',
                title: 'Datenschutzerklärung',
              },
              {
                path: '/affiliate-programm',
                title: 'Affiliate Programm',
              },
              {
                path: '/sitemap',
                title: 'Sitemap',
              },
            ].map((item) => (
              <div className="FooterNavBar__column" key={item.path}>
                <LinkWrapper
                  className="FooterNavBar__link"
                  href={item.path}
                  tabIndex={0}
                >
                  {item.title}
                </LinkWrapper>
              </div>
            ))}
          </div>

          <div className="Footer__center">
            <Logo
              lazyload={true}
              className="Footer__mainLogo"
              variant={LogoVariant.MainOnBackground}
            />
          </div>
        </section>
      </div>
    </footer>
  );
}
