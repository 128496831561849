import { Icon, IconSize, IconVariant } from '@/components/Icon';
import Modal, { ModalContent, ModalHeader } from '@/components/Modal';
import React from 'react';

const ModalGluecksspiraleInfo: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <Modal id="gluecksspirale-info">
      <ModalHeader>
        <span>Schließen</span>
        <Icon variant={IconVariant.Close} size={IconSize.Small} />
      </ModalHeader>
      <ModalContent>
        <h1>Erläuterungen zur GlücksSpirale</h1>
        <p>
          In einigen Bundesländern können Anteile an GlücksSpirale-Losen
          gespielt werden. Anfallende Gewinne werden entsprechend den erworbenen
          Anteilen ausbezahlt. Daher werden die GlücksSpirale-Gewinne mit einer
          Stelle hinter dem Komma ausgewiesen.
        </p>
        <p>
          * In einigen Bundesländern können Anteile an Glücksspirale-Losen
          gespielt werden. Anfallende Gewinne werden entsprechend den erworbenen
          Anteilen ausbezahlt. Daher werden die GlücksSpirale-Gewinne mit einer
          Stelle hinter dem Komma ausgewiesen.
        </p>
        <p>** 20 Jahre lang.</p>
        <p>
          *** Werden in der Gewinnklasse 6 mehr als 100 Gewinner pro
          Veranstaltung ermittelt, vermindert sich der Gewinn entsprechend.
        </p>
        <p>
          **** Werden in der Gewinnklasse 7 mehr als 10 Gewinner pro
          Veranstaltung ermittelt, vermindert sich der Gewinn bzw. die Rente
          entsprechend.
        </p>
      </ModalContent>
    </Modal>
  );
};

export default ModalGluecksspiraleInfo;
