import Logo from '@/components/Logo';
import { LogoVariant } from '@/components/LogoVariant';
import classnames from 'classnames';
import React from 'react';

interface WinningNumbersAdditionalGameProps {
  numbers?: string;
  logo?: LogoVariant;
  color?: string;
  title?: string;
  small?: boolean;
  disableLazyload?: boolean;
}

const splitWinningNumbers = function (numbers): string {
  return numbers?.split('').join(' ');
};

export const WinningNumbersAdditionalGame: React.FC<
  React.PropsWithChildren<WinningNumbersAdditionalGameProps>
> = ({ logo, color, title, numbers, small, disableLazyload }) => {
  return (
    <div
      className={classnames('WinningNumbersAdditionalGame', {
        'WinningNumbersAdditionalGame--small': small || false,
      })}
    >
      <span className="WinningNumbersAdditionalGame__text" style={{ color }}>
        {logo && (
          <Logo title={title} variant={logo} lazyload={!disableLazyload} />
        )}
        <span
          dangerouslySetInnerHTML={{ __html: splitWinningNumbers(numbers) }}
        />
      </span>
    </div>
  );
};
