export enum OddsVariant {
  Keno = 'keno',
  GluecksSpirale = 'gluecksspirale',
  Eurojackpot = 'eurojackpot',
  Lotto6aus49 = 'lotto6aus49',
  Super6Spiel77 = 'super6spiel77',
  Plus5 = 'plus5',
  TotoAW = 'totoaw',
  TotoEW = 'totoew',
}
